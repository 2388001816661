const [
  RED,
  ORANGE,
  YELLOW,
  GREEN,
  BLUE,
  PURPLE,
  GRAY,
  DARKGRAY,
  BLACK,
  COAL,
  SILVER,
  GOLD,
  PLATINUM,
  DIAMOND,
] = [
  "RED",
  "ORANGE",
  "YELLOW",
  "GREEN",
  "BLUE",
  "PURPLE",
  "GRAY",
  "DARKGRAY",
  "BLACK",
  "COAL",
  "SILVER",
  "GOLD",
  "PLATINUM",
];

const levels = new Map([
  [RED, { current: RED, days: 0, previous: RED, next: ORANGE }],
  [ORANGE, { current: ORANGE, days: 1, previous: RED, next: YELLOW }],
  [YELLOW, { current: YELLOW, days: 1, previous: ORANGE, next: GREEN }],
  [GREEN, { current: GREEN, days: 2, previous: YELLOW, next: BLUE }],
  [BLUE, { current: BLUE, days: 3, previous: GREEN, next: PURPLE }],
  [PURPLE, { current: PURPLE, days: 5, previous: BLUE, next: GRAY }],
  [GRAY, { current: GRAY, days: 8, previous: PURPLE, next: DARKGRAY }],
  [DARKGRAY, { current: DARKGRAY, days: 13, previous: GRAY, next: BLACK }],
  [BLACK, { current: BLACK, days: 21, previous: DARKGRAY, next: COAL }],
  [COAL, { current: COAL, days: 34, previous: BLACK, next: SILVER }],
  [SILVER, { current: SILVER, days: 55, previous: COAL, next: GOLD }],
  [GOLD, { current: GOLD, days: 89, previous: SILVER, next: PLATINUM }],
  [PLATINUM, { current: PLATINUM, days: 144, previous: GOLD, next: DIAMOND }],
  [DIAMOND, { current: DIAMOND, days: 233, previous: PLATINUM, next: DIAMOND }],
]);

export default levels;
