import Page from "../Components/Page";
import Button from "../Components/Button";
import styles from "./SignUpIn.module.css";

const SignUpIn = () => {
  return (
    <Page motionKey={"signUpIn"}>
      <div className={styles.SignUpIn}>
        <div className={styles.signUp}>
          <h2>Sign Up</h2>
          <label for={"signUp"}>Email: </label>
          <input type="text" name="signUp" />
          <label for={"password"}>Password: </label>
          <input type="password" name="password" />
          <label for={"repeatPassword"}>Repeat Passoword: </label>
          <input type="password" name="repeatPassword" />
          <div className={styles.buttonContainer}>
            <Button buttonStyle={"yellowButton"} size={"small"}>
              Sign Up
            </Button>
          </div>
        </div>
        <div className={styles.signIn}>
          <h2>Sign In</h2>
          <label for={"signIn"}>Email: </label>
          <input type="text" name="signIn" />
          <label for={"password"}>Password: </label>
          <input type="password" name="password" />
          <div className={styles.buttonContainer}>
            <Button buttonStyle={"yellowButton"} size={"small"}>
              Sign In
            </Button>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default SignUpIn;
