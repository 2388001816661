import styles from "./Button.module.css";

const Button = (props) => {
  const forwardedProps = { ...props };
  delete forwardedProps.buttonStyle;

  const style = styles[props.buttonStyle] ?? styles.button

  return (
    <button className={style} {...forwardedProps}>
      {props.children}
    </button>
  );
};

export default Button;
