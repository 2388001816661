import React, { useState } from "react";

import TextArea from "../Components/Textarea";
import Page from "../Components/Page";
import { saveQuestion } from "../utils/LocalQuestions";
import styles from "./QuestionEditor.module.css";

const QuestionEditor = () => {
  const [inputs, setInputs] = useState({});

  const handleInputChange = (event) => {
    setInputs((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSaveQuestion = async (event) => {
    event.preventDefault();
    const { answer, category, questionStem } = inputs;

    await saveQuestion({ answer, category, questionStem });
    setInputs({});
  };

  return (
    <Page motionKey={"question-editor"}>
      <h2 className={styles.header}> Question Editor</h2>
      <form className={styles.Form}>
        <label className={styles.label} htmlFor="questionStem">
          Enter a Question
        </label>
        <TextArea
          name="questionStem"
          onChange={handleInputChange}
          value={inputs.questionStem ?? ""}
        ></TextArea>
        <label className={styles.label} htmlFor="answer">
          Enter an Answer
        </label>
        <TextArea
          name="answer"
          onChange={handleInputChange}
          value={inputs.answer ?? ""}
        ></TextArea>
        <label className={styles.label} htmlFor="category">
          Enter a category
        </label>
        <input
          name="category"
          onChange={handleInputChange}
          type="text"
          value={inputs.category ?? ""}
        ></input>
        <button className={styles.button} onClick={handleSaveQuestion}>
          Save
        </button>
      </form>
    </Page>
  );
};

export default QuestionEditor;
