import React, { useState, useEffect } from "react";
import { getQuestionsForReview } from "../../utils/LocalQuestions";

import styles from "./Notifications.module.css";
import bell from "./bell.svg";

const Notifications = ({ handleSideNavAndRoute }) => {
  const [notifications, setNotifications] = useState(0);

  useEffect(() => {
    const getAndSetNotifications = async () => {
      const readyForReview = await getQuestionsForReview();
      if (!readyForReview) return;

      setNotifications(readyForReview.length);
    };

    window.addEventListener("questionsUpdated", getAndSetNotifications);
    getAndSetNotifications();
  });

  if (!notifications) {
    return <div className={styles.bellPlaceholder} aria-hidden={true} />;
  }

  return (
    <a
      href="review"
      className={styles.Notifications}
      onClick={(e) => handleSideNavAndRoute(e, "/review")}
    >
      <img src={bell} alt="notifications icon" height="40" width="40" />
      <div className={styles.numNotifications}>{notifications}</div>
    </a>
  );
};

export default Notifications;
