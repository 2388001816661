import React, { useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

import useAuth from "./Hooks/useAuth";
import * as db from "./firebase";

import Chrome from "./Chrome";
import Home from "./Home";
import Review from "./Review";
import QuestionEditor from "./QuestionEditor";
import SignUpIn from "./SignUpIn";
import LoadingScreen from './Components/LoadingScreen'

import styles from "./App.module.css";

function App() {
  const { user, loading, loadingSubscription, subscription } = useAuth();

  useEffect(() => {
    if (user) localStorage.setItem("userId", user.uid);
  }, [user]);

  if (loading) {
    return <LoadingScreen /> 
  }

  if (user) {
    if (subscription) {
      return <AuthenticatedApp />;
    } else if (loadingSubscription) {
      return <LoadingScreen />
    } else {
      return (
        <div>
          Sign up for a subscription <button onClick={db.logOut}>Logout</button>
        </div>
      );
    }
  } else {
    return <UnAuthenticatedApp />;
  }
}

const Routes = () => {
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter>
      <Switch key={location.pathname} location={location}>
        <Route path="/question-editor">
          <QuestionEditor />
        </Route>
        <Route path="/review">
          <Review />
        </Route>
        <Route path="/sign-up-in">
          <SignUpIn />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </AnimatePresence>
  );
};

function AuthenticatedApp() {
  return (
    <Router>
      <div className={styles.App}>
        <Chrome />
        <Routes />
      </div>
    </Router>
  );
}

function UnAuthenticatedApp() {
  return (
    <div className={styles.app}>
      <Chrome />
      <button onClick={db.signInWithGoogle}>Sign in with Google</button>
    </div>
  );
}

export default App;
