import React from "react";
import { motion } from "framer-motion";
import styles from "./Header.module.css";

import Notifications from "./Notifications";

const Header = (props) => {
  return (
    <div className={styles.Header}>
      <motion.div
        className={styles.burgerMenu}
        initial={{ rotate: 180 }}
        onClick={props.handleBurgerClick}
        animate={props.spinnerControls}
      >
        <div className={styles.burgerBar} />
        <div className={styles.burgerBar} />
        <div className={styles.burgerBar} />
      </motion.div>
      <a
        className={styles.title}
        href="home"
        onClick={(e) => props.handleSideNavAndRoute(e, "/")}
      >
        Space Cadet
      </a>
      <Notifications
        handleSideNavAndRoute={props.handleSideNavAndRoute}
        user={props.user}
      />
    </div>
  );
};

export default Header;
