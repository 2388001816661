import { motion } from "framer-motion";

const Page = ({ children, motionKey }) => {
  return (
    <motion.div key={motionKey} exit={{ opacity: 0 }}>
      {children}
    </motion.div>
  );
};

export default Page;
