import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAnimation } from "framer-motion";

import Header from "./Header";
import SideNav from "./SideNav";

const Chrome = ({ user }) => {
  const history = useHistory();
  const [isOpen, setIsOpenAndRender] = useState(false);
  const spinnerControls = useAnimation();

  const fireOpenCloseAnimation = () => {
    if (isOpen) {
      spinnerControls.start({ rotate: 180, transition: { duration: 0.25 } });
    } else {
      spinnerControls.start({ rotate: 0, transition: { duration: 0.25 } });
    }
  };

  const openSideNav = () => setIsOpenAndRender(!isOpen);

  const handleSideNavAndRoute = (e, url) => {
    e.preventDefault();
    if (isOpen) {
      fireOpenCloseAnimation();
      openSideNav();
    }
    history.push(url);
  };

  const handleBurgerClick = () => {
    fireOpenCloseAnimation();
    openSideNav();
  };

  return (
    <div>
      <Header
        handleBurgerClick={handleBurgerClick}
        handleSideNavAndRoute={handleSideNavAndRoute}
        isOpen={isOpen}
        spinnerControls={spinnerControls}
        user={user}
      />
      <SideNav handleSideNavAndRoute={handleSideNavAndRoute} isOpen={isOpen} />
    </div>
  );
};

export default Chrome;
