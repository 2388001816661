import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithRedirect,
} from "firebase/auth";
import {
  doc,
  getFirestore,
  Timestamp,
  collection,
  updateDoc,
  addDoc,
  where,
  query,
  getDocs,
  getDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDUZqlzP_4f0hCK2jMhn9Mrjn9fRLI-BJQ",
  authDomain: "space-cadet-fced6.firebaseapp.com",
  projectId: "space-cadet-fced6",
  storageBucket: "space-cadet-fced6.appspot.com",
  messagingSenderId: "145443320829",
  appId: "1:145443320829:web:5087ae6ff74641c55b1f94",
  measurementId: "G-HQLLSVEHYL",
};

const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

export async function signInWithGoogle() {
  const provider = new GoogleAuthProvider();
  await signInWithRedirect(auth, provider);
}

export function checkAuth(callback) {
  return onAuthStateChanged(auth, callback);
}

export async function logOut() {
  await signOut(auth);
}

export function getServerTime() {
  return new Timestamp();
}

export function getCurrentTime() {
  const ts = Timestamp.now();
  return ts.seconds;
}

export async function getQuestions() {
  const userId = localStorage.getItem("userId");

  const q = query(collection(db, "questions"), where("user", "==", userId));
  const snapshot = await getDocs(q);

  return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
}

export async function postQuestion(question) {
  const userId = localStorage.getItem("userId");
  await addDoc(collection(db, "questions"), { ...question, user: userId });
}

export async function updateQuestion(question) {
  const docRef = doc(db, "questions", question.id);
  await updateDoc(docRef, question);
}

export async function getSubscription() {
  const userId = localStorage.getItem("userId");
  const snapshot = await getDoc(doc(db, "subscriptions", userId));
  const subscription = snapshot.data();

  return subscription ? true : false;
}
