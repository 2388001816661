import styles from "./Textarea.module.css";

const TextArea = ({ name, onChange, value }) => {
  return (
    <textarea
      className={styles.TextArea}
      cols={33}
      name={name}
      onChange={onChange}
      rows={6}
      value={value}
    ></textarea>
  );
};

export default TextArea;
