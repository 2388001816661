import React, { lazy, Suspense, useLayoutEffect, useState } from "react";
import Page from "../Components/Page";
import { getNextQuestion } from "../utils/LocalQuestions";

import styles from "./Review.module.css";

const loadReviewCards = () => import("./ReviewCards");
const ReviewCards = lazy(loadReviewCards);

const Review = () => {
  const [nextQuestion, setNextQuestion] = useState(null);
  const [isQuestion, setIsQuestion] = useState(true);

  useLayoutEffect(() => {
    const getAndSetNextQuestion = async () => {
      const question = await getNextQuestion();
      setNextQuestion(question);
    };

    if (nextQuestion) {
      loadReviewCards();
      return;
    } else {
      getAndSetNextQuestion();
    }
  }, [nextQuestion]);

  const toggleIsQuestion = () => setIsQuestion(!isQuestion);

  const NoQuestions = () => (
    <div className={styles.header}>You have no questions ready for review</div>
  );

  return (
    <Page motionKey={"review"}>
      <div className={styles.Review}>
        <Suspense fallback={<NoQuestions />}>
          {nextQuestion && (
            <ReviewCards
              nextQuestion={nextQuestion}
              isQuestion={isQuestion}
              toggleIsQuestion={toggleIsQuestion}
              setNextQuestion={setNextQuestion}
            />
          )}
        </Suspense>
      </div>
    </Page>
  );
};

export default Review;
