import { DateTime } from "luxon";
import levels from "../utils/levels";
import * as db from "../firebase";

const isQuestionReadyForReview = (question) => {
  const currentTimeInSeconds = db.getCurrentTime();
  const { lastAnswered, level } = question;

  if (currentTimeInSeconds && lastAnswered) {
    const currentTime = new DateTime.fromSeconds(currentTimeInSeconds);
    const lastAnsweredTime = new DateTime.fromSeconds(lastAnswered);

    return (
      Math.abs(currentTime.diff(lastAnsweredTime, ["days"]).toObject().days) >
      levels.get(level).days
    );
  }
};

export const getNextQuestion = async () => {
  const questions = await getQuestionsForReview();
  const randomPosition = Math.floor(Math.random() * questions.length);

  window.dispatchEvent(new Event("questionsUpdated"));
  return questions[randomPosition];
};

export const getQuestionsForReview = async () => {
  const questions = await db.getQuestions();
  if (questions) {
    const questionsReadyForReview = questions.filter(isQuestionReadyForReview);
    return questionsReadyForReview;
  }
};

export const saveQuestion = async (question) => {
  const { answer, category, questionStem } = question;

  const questionToBeSaved = {
    answer,
    category,
    lastAnswered: db.getCurrentTime(),
    level: levels.get("RED").current,
    questionStem,
  };

  db.postQuestion(questionToBeSaved);
  window.dispatchEvent(new Event("questionsUpdated"));
};

export const answerQuestion = async (questionId, correct) => {
  const questions = await getQuestionsForReview();
  const questionIndex = questions.findIndex((question) => {
    return question.id === questionId;
  });
  const question = questions[questionIndex];

  question.lastAnswered = db.getCurrentTime();

  if (correct) {
    question.level = levels.get(question.level).next;
  } else {
    question.level = levels.get(question.level).previous;
  }

  await db.updateQuestion(question);
  window.dispatchEvent(new Event("questionsUpdated"));
};
