import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import * as db from "../../firebase";
import styles from "./SideNav.module.css";

const SideNav = (props) => {
  return (
    <AnimatePresence>
      {props.isOpen ? (
        <motion.div
          animate={{
            x: "100vw",
            transition: { duration: 0.25 },
          }}
          exit={{
            x: "0vw",
            transition: { duration: 0.25 },
          }}
          intial={false}
          className={styles.SideNav}
        >
          <a
            className={styles.link}
            href="question-editor"
            onClick={(event) =>
              props.handleSideNavAndRoute(event, "/question-editor")
            }
          >
            Question Editor
          </a>
          <a
            className={styles.link}
            href="question-editor"
            onClick={(event) => props.handleSideNavAndRoute(event, "/review")}
          >
            Review
          </a>
          <a
            className={styles.link}
            href="logout"
            onClick={(event) => {
              event.preventDefault();
              db.logOut();
            }}
          >
            Log out
          </a>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};

export default SideNav;
