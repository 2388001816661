import { useEffect, useState } from "react";
import * as db from "../firebase";

function useAuth() {
  const [user, setUser] = useState(null);
  const [subscription, setSubscription] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingSubscription, setLoadingSubscription] = useState(true);

  const checkSubscription = async (uid) => {
    const subscribed = await db.getSubscription(uid);
    setSubscription(subscribed);
    setLoadingSubscription(false);
  };

  useEffect(() => {
    return db.checkAuth((user) => {
      setUser(user);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (user) checkSubscription(user.uid);
  }, [user]);

  return { user, loading, loadingSubscription, subscription };
}

export default useAuth;
