import Page from "../Components/Page";

import styles from "./Home.module.css";

const Home = () => {
  return (
    <Page motionKey="home">
      <div className={styles.Home}>
        <p>
          Space Cadet is a spaced repition system. Add questions and they will
          automatically come up for review at the most ideal intervals to lock
          the information into your head permanently.
        </p>
        <p>You can add this app to your home screen from your browser.</p>
        <p>
          Create an account to share questions between devices, as well as
          unlocking notifications.
        </p>
      </div>
    </Page>
  );
};

export default Home;
