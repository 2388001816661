import { motion } from 'framer-motion'
import styles from './LoadingScreen.module.css'

const LoadingScreen = () => {
  const loaderVariants = {
    animationTwo: {
      rotate: -360,
      y: [-50, 50],
      transition: {
        loop: Infinity,
        duration: 1,
        ease: 'linear',
        y: {
          yoyo: Infinity,
          duration: 1
        }
      }
    }
  }

  return (
    <>
    <div className={styles.LoadingScreen}>
      <motion.div className={styles.loader} variants={loaderVariants} animate="animationTwo">
      </motion.div> 
    </div>
    </>
  )
}

export default LoadingScreen